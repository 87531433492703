@tailwind base;
@tailwind components;
@tailwind utilities;

/* ------------>>>>>>>  ESTILOS GENERALES  <<<<<<<------------ */

@layer base {

    /* -----  BASE  ----- */

    html {
      @apply font-league-spartan text-gray-900 dark:text-white;
    }
    .App {
      @apply fixed top-0 left-0 w-screen h-screen bg-fondomovillight bg-cover bg-top dark:bg-dark sm:bg-light sm:dark:bg-dark sm:bg-cover sm:bg-center;
    }
    a {
      @apply text-pink-800 dark:text-pink-500 hover:text-pink-500 dark:hover:text-pink-500 hover:scale-105 underline-offset-2 hover:underline font-bold dark:font-bold hover:font-bold dark:hover:font-bold transition-all duration-300 ease-in-out;
    }
    a.link-con-flecha {
      @apply text-pink-800 dark:text-pink-500 hover:text-pink-500 dark:hover:text-pink-500 hover:scale-105 after:content-['_↗'] underline-offset-2 hover:underline font-bold dark:font-bold hover:font-bold dark:hover:font-bold transition-all duration-300 ease-in-out;
    }
    p.parrafo {
      @apply px-4 pb-0.5 leading-6 flow-root whitespace-normal font-league-spartan dark:font-light font-normal sm:text-xl text-left text-gray-900 dark:text-white;
    }
    h1.titulo-1 {
      @apply font-league-spartan font-bold text-5xl sm:text-8xl text-center sm:leading-[5.5rem];
    }
    h2.titulo-2 {
      @apply font-league-spartan font-bold text-[1.66rem] sm:text-6xl text-center sm:text-center leading-[2rem] sm:leading-[4rem];
    }
    h3.titulo-3 {
      @apply font-league-spartan font-bold text-xl sm:text-4xl text-center leading-6 sm:leading-8;
    }
    h4.titulo-4 {
      @apply spartan font-bold text-sm sm:text-2xl text-center leading-4;
    }
    .color-texto-primario {
      @apply dark:text-white text-gray-900; 
    }
    .logo-jj {
      @apply mt-5 relative w-44 h-[2.3rem] sm:w-[12rem] sm:h-[2.5rem] 2xl:mr-24;
    }
    .dark-svg {
      @apply dark:fill-white fill-gray-900;
    }

    /* -----  TIPOGRAFÍAS  ----- */

    .spartan {
      @apply font-league-spartan;
    }
    .spartan-bold {
      @apply font-league-spartan font-bold;
    }
    .spartan-medium {
      @apply font-league-spartan font-medium;
    }
    .spartan-regular {
      @apply font-league-spartan font-normal;
    }
    .spartan-light {
      @apply font-league-spartan font-light;
    }

    /* -----  TRANSICIONES  ----- */

    .transiciones {
      @apply transition-all duration-300 ease-in-out;
    }

    /* -----  COLORES ESPECIALES Y EFECTOS  ----- */

    .fondo-translucido {
      @apply backdrop-blur-lg bg-white dark:bg-black dark:bg-opacity-60 sm:bg-opacity-20 bg-opacity-90;
    }
    .titulo-degradado {
      @apply bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500 hover:from-violet-500 hover:to-orange-500;
    }
    .titulo-degradado-nombre {
      @apply bg-clip-text text-transparent bg-gradient-to-r from-orange-500 via-purple-500 to-pink-500;
    }
    .borde-redondeado {
      @apply border border-gray-900 dark:border-white rounded-3xl;
    }
    .decoracion-titulo-destacado {
      @apply underline decoration-4 decoration-pink-500 underline-offset-4;
    }
    .decoracion-texto-destacado {
      @apply underline decoration-2 decoration-pink-500 underline-offset-4;
    }
}

/* ------------>>>>>>>  ESTILOS COMPONENTES  <<<<<<<------------ */

@layer components {    

    /* -----  SPLASH SELECTOR DE IDIOMAS  ----- */

    .contenedor-selector-idiomas {
      @apply z-[999] grid place-items-center w-screen h-screen absolute -top-10 left-0 bg-transparent dark:bg-transparent;
    }
    .subcontenedor-selector-idiomas {
      @apply sm:w-1/2 md:w-2/5 2xl:w-1/3 pt-10 pb-10 pl-5 pr-5 border border-gray-900 dark:border-white hover:border-pink-500 dark:hover:border-pink-500 rounded-3xl; 
    }
    .contenedor-logo-y-switcher-selector-idiomas {
      @apply flex justify-between flex-row mr-3 sm:mr-3 md:mr-3 lg:mr-3 xl:mr-3 2xl:mr-5;
    }
    .logo-jj-lang-selector {
      @apply bg-cover bg-logolight dark:bg-logodark relative ml-4 w-40 h-[2.1rem] sm:w-[12rem] sm:h-[2.5rem] hover:scale-105 hover:-hue-rotate-60;
    }
    .switch-selector-tema {
      @apply rounded-full hover:ring-1 hover:ring-pink-500 hover:ring-offset-4 ring-offset-[#CDB2DB] dark:ring-offset-[#2B195B];
    }
    .titulo-selecciona-idioma {
      @apply mt-5 text-center sm:text-sm md:text-lg xl:text-2xl 2xl:text-2xl font-league-spartan text-gray-900 dark:text-white;
    }
    .contenedor-botones-idioma {
      @apply flex flex-row mt-0;
    }
    .boton-idioma {
      @apply mt-4 basis-1/2 mr-2 hover:text-pink-500; 
    }
    .estilos-interactivos-botones {
      @apply text-sm 2xl:text-2xl xl:text-xl lg:text-xl md:text-lg sm:text-sm hover:scale-105; 
    }

    /* -----  FIN SPLASH SELECTOR DE IDIOMAS  ----- */

    /* -----  HEADER  ----- */

    .contenedor-header {
      @apply relative w-screen h-auto z-20; 
    }
    .header {
      @apply hidden sm:visible sticky top-0 left-0 sm:flex justify-items-center justify-between px-8 bg-transparent;
    }
    .header-movil {
      @apply z-40 grid place-items-center absolute top-0 pt-2 w-full visible sm:invisible md:invisible lg:invisible xl:invisible 2xl:invisible bg-transparent;
    }
    .components-container {
      @apply h-screen max-h-[39rem] mb-20 absolute top-20 left-5;
    }

    /* -----  FIN HEADER  ----- */

    /* -----  NAV PRINCIPAL  ----- */

    .nav-principal {
      @apply invisible sm:visible md:visible lg:visible xl:visible 2xl:visible opacity-95 dark:opacity-80 bg-gray-900 dark:bg-white my-5 rounded-full hover:ring-1 hover:ring-pink-500 hover:ring-offset-4 ring-offset-[#6B42BE] dark:ring-offset-[#2B2665];
    }
    .contenedor-menu {
      @apply flex text-lg font-bold font-league-spartan px-5 space-x-4 align-middle py-1.5;
    }
    .botones-menu {
      @apply text-center text-white dark:text-gray-900 px-2 cursor-pointer dark:hover:text-white z-50 min-w-[6rem] max-w-[6.1rem];
    }
    .fondo-botones-menu {
      @apply border-2 border-gray-800 dark:border-white rounded-full bg-[#FF00A1] w-28 h-10 -mt-10;
    }
    .boton-menu-movil {
      @apply flex flex-row mt-5 bg-transparent font-league-spartan font-bold text-xl border border-gray-900 dark:border-white rounded-full py-2;
    }
    .texto-boton-menu-movil {
      @apply text-xl pt-0.5 pl-5 text-gray-900 dark:text-white text-left w-64;
    }
    .contenedor-desplegable-menu-movil {
      @apply pt-5 px-5 pb-0 text-gray-900 dark:text-white text-xl mt-2 border border-gray-900 dark:border-white rounded-3xl;
    }
    .linea-divisoria-menu-movil {
      @apply rounded-full mt-2 mb-5 h-[0.06rem] w-full bg-gray-900 dark:bg-white;
    }
    .switch-menu-movil {
      @apply mb-5 rounded-full hover:ring-1 hover:ring-pink-500 hover:ring-offset-4 ring-offset-[#999999] dark:ring-offset-[#2B195B];
    }

    /* -----  FIN NAV PRINCIPAL  ----- */

    /* -----  SWITCH  ----- */

    .switch {
      @apply invisible sm:visible md:visible rounded-full hover:ring-1 hover:ring-pink-500 hover:ring-offset-4 ring-offset-[#8F57CD] dark:ring-offset-[#2B195B];
    }

    /* -----  FIN SWITCH  ----- */

    /* -----  HERO  ----- */

    .contenedor-hero {
      @apply flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row fixed top-0 left-0 w-screen h-screen;
    }
    .contenedor-saludo-nombre {
      @apply mt-20 sm:mt-0 text-center md:text-left lg:text-left xl:text-left 2xl:text-left grid justify-items-end items-center w-[100%];
    }
    .saludo {
      @apply md:pl-20 lg:pl-20 xl:pl-20 2xl:pl-20 font-league-spartan dark:text-white font-bold text-[2.5rem] sm:text-2xl md:text-6xl xl:text-6xl 2xl:text-7xl;
    }
    .nombre {
      @apply p-0 md:pl-20 lg:pl-20 xl:pl-20 2xl:pl-20 -mt-3.5 lg:-mt-1.5 xl:-mt-1 2xl:-mt-1 w-full font-league-spartan font-bold text-5xl sm:text-7xl md:text-6xl lg:text-8xl xl:text-8xl 2xl:text-9xl cursor-none;
    }
    .roles {
      @apply px-4 sm:px-0 md:pl-20 lg:pl-20 xl:pl-20 2xl:pl-20 max-w-2xl 2xl:max-w-4xl font-league-spartan dark:text-white font-light text-xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-left;
    }
    .foto-juan-jaramillo {
      @apply md:relative fixed bottom-0 m-0 p-0 sm:ml-0 md:-ml-20 lg:-ml-24 xl:-ml-28 2xl:-ml-28 sm:h-screen md:h-screen lg:h-screen xl:h-screen 2xl:h-screen h-auto w-auto ;
    }
    .boton-conoce-mas {
      @apply sm:mx-3 md:mx-3 lg:mx-2 xl:mx-2 2xl:mx-2 mt-3 sm:mt-3 md:mt-4 lg:mt-4 xl:mt-4 2xl:mt-4 pt-1 text-lg sm:text-lg md:text-xl lg:text-xl 2xl:text-2xl border sm:border border-gray-900 text-gray-900 dark:text-white font-league-spartan font-medium dark:border-white dark:hover:border-pink-500 hover:border-pink-500;
    }

    /* -----  FIN HERO  ----- */

    /* -----  MENÚ FIJO  ----- */

    .contenedor-menu-fijo {
      @apply flex z-50 w-[100%] h-auto justify-between fixed bottom-0 left-0 p-6 bg-transparent;
    }
    .contenedor-menu-sociales {
      @apply flex justify-start max-h-10;
    }
    .menu-sociales {
      @apply flex cursor-pointer rounded-full p-1 justify-items-center justify-between border border-gray-900 dark:border-white hover:border-pink-500 dark:hover:border-pink-500;
    }
    .svg-sociales {
      @apply px-2.5 hover:text-pink-500;
    }

      /* -----  WHATSAPP  ----- */

      .contenedor-boton-whatsapp {
        @apply pb-20 -mb-24 pt-3 w-16 justify-end mr-3 rounded-t-full border border-gray-900 dark:border-white hover:border-pink-500 dark:hover:border-pink-500;
      }
      .img-whatsapp {
        @apply mx-3;
      }

      /* -----  SPEECH BUBBLE  ----- */

      .contenedor-speech-bubble {
        @apply fixed bottom-24 right-9 font-league-spartan z-50;
      }
      .cuadro-grande-speech-bubble {
        @apply drop-shadow-lg relative bg-gray-100 text-gray-900 px-5 py-3 rounded-xl;
      }
      .flecha-speech-bubble {
        @apply drop-shadow-lg -z-10 absolute bg-gray-100 w-5 h-9 rotate-45 -mt-6 right-5;
      }
      .titulo-dudas {
        @apply text-gray-900 font-league-spartan text-lg sm:text-[1.65rem] md:text-[1.65rem] xl:text-[1.65rem] 2xl:text-[1.65rem];
      }
      .invitacion-dudas {
        @apply text-right text-gray-900 font-league-spartan text-xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl font-bold -mt-1 xl:mt-0 2xl:mt-0;
      }
      .boton-cerrar-speech-bubble {
        @apply absolute bottom-16 right-[13] sm:bottom-[4.8rem] sm:right-[14em] px-[0.1em] sm:text-2xl drop-shadow-lg font-league-spartan font-bold text-pink-500 bg-gray-100 hover:text-white rounded-full;
      }

      /* -----  LANGUAGE SWITCHER  ----- */

      .contenedor-language-switcher {
        @apply z-50 fixed left-0 top-[45%];
      }
      .language-switcher-spanish {
        @apply flex flex-row-reverse text-lg cursor-pointer font-league-spartan font-medium rounded-full border pr-2 pl-10 pt-1 mt-3 sm:mt-0 border-gray-900 dark:border-white dark:text-white hover:border-pink-500 hover:text-pink-500 dark:hover:border-pink-500 dark:hover:text-pink-500;
      }
      .language-switcher-english {
        @apply flex flex-row-reverse text-lg cursor-pointer font-league-spartan font-medium rounded-full border pr-2 pl-10 pt-1 sm:mt-0 border-gray-900 dark:border-white dark:text-white hover:border-pink-500 hover:text-pink-500 dark:hover:border-pink-500 dark:hover:text-pink-500;
      }
      .icono-language-switcher {
        @apply rounded-full w-7 h-7 sm:w-5 sm:h-5 sm:ml-2 ml-2.5 sm:mt-0.5 mb-1 sm:mb-0;
      }

    /* -----  FIN MENÚ FIJO  ----- */

    /* -----  FOOTER  ----- */

    .footer-general {
      @apply grid place-items-center font-league-spartan text-center text-lg border border-gray-900 dark:border-white rounded-3xl m-2 p-1 sm:p-3;
    }
    .icono-email-footer {
      @apply fill-none w-[24px] h-[24px] stroke-2 stroke-pink-900 dark:stroke-pink-500 inline pb-0.5 mr-1;
    }
    .logo-footer-movil {
      @apply grid place-items-center grid-cols-3 px-[25%] py-[5%] sm:px-0 md:py-0 lg:px-0 xl:px-0 2xl:px-0 h-auto sm:h-0 md:h-0 lg:h-0 xl:h-0 2xl:h-0 visible sm:invisible md:invisible lg:invisible xl:invisible 2xl:invisible;
    }

    /* -----  FIN FOOTER  ----- */
  }

  /* ------------>>>>>>>  ESTILOS INTERNAS  <<<<<<<------------ */

  @layer utilities {

    /* -----  ESTILOS GENERALES INTERNAS  ----- */

    .placeholder-internas {
      @apply flex items-center justify-center w-[300%];
    }
    .contenedor-internas {
      @apply mb-56 sm:pb-0 flex flex-col p-0 ml-6 sm:flex-col md:flex-col lg:flex-col xl:flex-col 2xl:flex-col static sm:p-2.5 md:p-2.5 lg:p-2.5 xl:p-2.5 2xl:p-2.5 my-2 w-[90%] 2xl:w-[80rem] sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0 2xl:mb-0 mx-[1%] sm:mx-[3.5%] 2xl:mx-[5%] h-full sm:h-[37rem] sm:max-h-[44rem] 2xl:h-[80%] 2xl:mt-[1.5%] overflow-y-auto overflow-x-hidden text-gray-900 dark:text-white;
    }
    .una-columna-interna {
      @apply flex flex-col m-1 p-3;
    }
    .contenedor-columnas {
      @apply flex sm:flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row 2xl:flex-row px-3 sm:py-4 sm:w-full justify-center items-center;
    }
    .columna {
      @apply flex flex-col items-center justify-center mb-2 sm:mx-2 sm:w-full sm:px-3 pb-2 sm:pt-3; 
    } 
    .img-con-borde-internas {
      @apply m-1 ring-1 ring-gray-900 dark:ring-white hover:ring-4 rounded-3xl;
    }
    .img-circular-borde-rosa-internas {
      @apply mt-0 sm:mt-28 ring-2 ring-gray-900 dark:ring-white hover:ring-pink-500 dark:hover:ring-pink-500 hover:ring-4 rounded-full;
    }
    .img-sin-borde-internas {
      @apply my-2 sm:m-2 rounded-3xl;
    }

    /* -----  FIN ESTILOS GENERALES INTERNAS  ----- */

    /* -----  PÁGINA ABOUT  ----- */
    
    .contenedor-logos-flotantes {
      @apply flex flex-row sm:gap-5 relative top-10 h-[1.3rem] w-[30%] ml-[35%] sm:ml-48 scale-75 sm:scale-105;
    }
    .subcontenedor-logos-flotantes {
      @apply basis-1/3;
    }
    .google-about {
      @apply w-[70px] h-[70px] mt-[20px] mb-[5px];
    }
    .react-about {
      @apply w-[100px] h-[100px];
    }
    .github-about {
      @apply w-[60px] h-[60px] mt-[10px];
    }
    .facebook-about {
      @apply w-[80px] h-[80px]
    }
    .flutter-about {
      @apply w-[80px] h-[80px] mt-[20px];
    }
    .vue-about {
      @apply w-[50px] h-[50px] mt-[5px];
    }
    .netlify-about {
      @apply w-[70px] h-[70px] mt-[15px];
    }
    .framer-about {
      @apply w-[80px] h-[80px] mt-[15px];
    }
    .figma-about {
      @apply w-[60px] h-[60px] mt-[25px] ml-[10px];
    }
    .tailwind-about {
      @apply w-[60px] h-[60px] mt-[15px];
    }
    .degradado-titulo-interactivo {
      @apply bg-clip-text text-transparent bg-gradient-to-r from-orange-500 via-purple-500 to-pink-500;
    }
    ul.listado-de-tareas {
      @apply spartan text-lg sm:text-3xl text-center sm:text-left leading-3 sm:leading-10 list-item;
    }
    ul.listado-de-tareas li {
      @apply cursor-pointer;
    }
    .icono-listado-de-tareas {
      @apply w-[2.1rem] h-[2.1rem] mr-2 pb-1.5 stroke-2 inline fill-none stroke-pink-500;
    }
    .titulo-servicios-perfil-about {
      @apply group-hover:underline group-hover:underline-offset-8 group-hover:decoration-pink-500;
    }
    .descripcion-servicios-perfil-about {
      @apply text-gray-900 sm:pl-10 py-2 sm:py-2 text-sm sm:text-lg font-light dark:font-light transition-all duration-300 ease-in-out hover:underline hover:after:content-['_↗'] hover:font-light hover:text-gray-900 dark:text-white dark:hover:font-light dark:hover:text-white;
    }
    .container-clients-slider {
      @apply spartan max-h-48;
    }
    .container-logo-slider {
      @apply transition-all duration-300 ease-in-out max-h-44 ml-[38%];
    }

      /* -----  AVISO "HAZ SCROLL"  ----- */

      .contenedor-aviso-haz-scroll {
        @apply grid place-items-center mt-5;
      }
      .contenedor-mouse-aviso-haz-scroll {
        @apply px-1.5 pt-2 w-10 h-10 border-t border-l border-r border-gray-900 dark:border-white rounded-t-full;
      }
      .mouse-aviso-haz-scroll {
        @apply w-[26px] h-[26px] stroke-gray-900 dark:stroke-white fill-none dark:fill-none; 
      }
      .contenedor-flecha-aviso-haz-scroll {
        @apply px-1.5 pt-1 w-10 h-10 border-b border-l border-r border-gray-900 dark:border-white rounded-b-full;
      }
      .flecha-aviso-haz-scroll {
        @apply w-[26px] h-[26px] stroke-gray-900 dark:stroke-white fill-none dark:fill-none; 
      }
      .texto-aviso-haz-scroll {
        @apply text-gray-900 dark:text-white spartan-medium text-sm my-0.5
      }

      /* -----  POPOVER CONTACTO  ----- */

      .contenedor-popover-contacto {
        @apply w-full px-6 sm:px-44;
      }
      .trigger-popover-contacto {
        @apply w-full rounded-full py-2;
      }
      .panel-popover-contacto {
        @apply backdrop-blur-lg bg-white dark:bg-black dark:bg-opacity-60 sm:bg-opacity-20 bg-opacity-90 hover:border-pink-500 absolute left-[42%] sm:left-1/2 z-10 mt-3 w-[100%] sm:w-screen max-w-sm -translate-x-1/2 transform sm:px-0 lg:max-w-3xl;
      }
      .contenedor-botones-popover-contacto {
        @apply relative sm:grid gap-8 sm:p-7 lg:grid-cols-3;
      }
      .boton-popover-contacto {
        @apply flex sm:-my-3 items-center justify-center rounded-full hover:border hover:border-pink-500 hover:no-underline;
      }
      .icono-boton-popover-contacto {
        @apply flex h-16 w-16 shrink-0 items-center justify-center text-gray-900 dark:text-white sm:h-8 sm:w-8;
      }
      .texto-boton-popover-contacto {
        @apply text-lg text-gray-900 dark:text-white;
      }
    .slider-container {
      @apply relative w-full h-72 max-w-md mx-auto overflow-hidden;
    }
    
    .item {
      @apply flex flex-col items-center justify-center absolute -mt-5 top-0 left-0 w-full h-full text-2xl font-semibold text-gray-800 dark:text-white;
    }

    /* -----  FIN PÁGINA ABOUT  ----- */

    /* -----  PÁGINA WORK  ----- */

    .contenedor-componente-work {
      @apply;
    }
    .boton-visitar-sitio-web-work {
      @apply my-3 pt-3 pb-2 hover:text-pink-500 hover:border-pink-500 cursor-pointer;
    }

    /* -----  FIN PÁGINA WORK  ----- */
    
    /* -----  PÁGINA CONTACT  ----- */

    .contenedor-componente-contacto {
      @apply static overflow-auto float-left w-[70%] max-h-72 sm:h-[35rem] sm:max-h-[42rem] 2xl:h-[100%] items-center justify-center py-3 pt-8;
    }
    .titulo-contacto {
      @apply mt-7 text-4xl text-center leading-7;
    }

      /* -----  FORMULARIO DE CONTACTO  ----- */

      .contenedor-formulario-contacto {
        @apply mb-5 px-40 w-full h-auto min-h-min;
      }
      .contenedor-campo-formulario-contacto {
        @apply w-full p-3 -mt-2 mb-0 text-center;
      }
      .campo-formulario-contacto {
        @apply w-full mt-0 mb-0 dark:placeholder:text-white placeholder:text-gray-900 hover:placeholder:text-pink-500 dark:hover:placeholder:text-pink-500 text-xl bg-transparent pl-4 pt-3 pb-3 rounded-full border dark:border-white border-gray-900 hover:border-pink-500 dark:hover:border-pink-500 hover:text-pink-500 dark:hover:text-pink-500 hover:scale-105 focus:text-pink-500 dark:focus:text-pink-500 focus:border-pink-500 dark:focus:border-pink-500 focus:scale-105;
      }
      .texto-error-formulario-contacto {
        @apply text-red-500 mt-3 -mb-3 text-center;
      }
      .contenedor-botones-formulario-contacto {
        @apply grid justify-center align-middle px-2;
      }
      .boton-enviar-formulario-contacto {
        @apply w-auto min-w-[15vw] my-3 pt-3 pb-2 hover:text-green-500 hover:border-green-500 cursor-pointer;
      }
      .boton-restablecer-formulario-contacto {
        @apply my-3 pt-3 pb-2 hover:text-red-500 hover:border-red-500 cursor-pointer;
      }

      /* -----  VENTANA DE CONFIRMACIÓN RESET FORMULARIO  ----- */

      .contenedor-reset-formulario-contacto {
        @apply pt-10 pb-10 pl-5 pr-5 border border-gray-900 dark:border-white hover:border-pink-500 dark:hover:border-pink-500 rounded-3xl cursor-move backdrop-blur-lg bg-white dark:bg-black dark:bg-opacity-60 sm:bg-opacity-90 bg-opacity-90;
      }

      .contenedor-conf-reset-formulario-contacto {
        @apply grid place-items-center z-[999] w-[70%] h-screen absolute -mt-[20vh] top-0 left-[15%] bg-transparent dark:bg-transparent;
      }
      .titulo-importante-reset-formulario-contacto {
        @apply font-league-spartan font-bold text-6xl text-center leading-[4rem] ml-5 -mt-1 dark:text-green-500 text-green-500;
      }
      .icono-importante-reset-formulario-contacto {
        @apply w-[50px] h-[50px] stroke-2 fill-none dark:stroke-green-500 text-green-500;
      }
      .btn-aceptar-reset-formulario-contacto {
        @apply text-gray-900 dark:text-white hover:text-green-500 dark:hover:text-green-500 dark:border-white border-gray-900 hover:border-green-500 dark:hover:border-green-500 mt-4 basis-1/2 mr-2;
      }
      .icono-aceptar-reset-formulario-contacto {
        @apply stroke-gray-900 dark:stroke-white group-hover:stroke-green-500 fill-none stroke-2 w-[24px] h-[24px] mb-1.5 ml-0 -mr-2;
      }
      .btn-cancelar-reset-formulario-contacto {
        @apply text-gray-900 dark:text-white hover:text-red-500 dark:hover:text-red-500 dark:border-white border-gray-900 hover:border-red-500 dark:hover:border-red-500 mt-4 basis-1/2 mr-2;
      }
      .icono-cancelar-reset-formulario-contacto {
        @apply stroke-gray-900 dark:stroke-white group-hover:stroke-red-500 fill-none stroke-2 w-[24px] h-[24px] mb-1.5 ml-0 -mr-2;
      }


    /* -----  FIN PÁGINA CONTACT  ----- */

}